import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  B1Blue,
  B2LightBlue,
  colors,
  H1White,
  H2Blue,
  standardWrapper,
} from "../../../styles/helpers"
import styled from "styled-components"

import smileImg from "../../../images/smile.png"

const getData = graphql`
  {
    reviews: allWpReview {
      edges {
        node {
          title
          postTypeReviews {
            content
          }
        }
      }
    }
  }
`

const CustomerReviews = ({ data }) => {
  const reviewData = useStaticQuery(getData)
  if (!data.reviewsDisplay && !reviewData.reviews.edges.length > 0) return null

  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>Customer Reviews</h2>
        </div>
        <div className="reviews">
          {reviewData.reviews.edges.map((review, index) => {
            return (
              <ReviewItem iseven={index % 2 === 0} key={index}>
                <div>
                  <h3>{review.node.title}</h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: review.node.postTypeReviews.content,
                  }}
                />
              </ReviewItem>
            )
          })}
        </div>
        <div className="load-more-btn">
          <button>
            <span className="btn-wrap">
              <span className="btn-img">
                <img src={smileImg} alt="Load More" />
              </span>
              <span className="btn-text">Load More</span>
            </span>
          </button>
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 10rem 0;

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1White};
      display: inline-block;
      padding: 0 2rem;
      background-color: ${colors.colorPrimary};
    }
  }

  .reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .load-more-btn {
    width: 100%;
    margin-top: 5rem;
    text-align: center;

    button {
      ${B1Blue};

      border: none;
      background-color: transparent;
      font-weight: bold;
      cursor: pointer;

      .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-img {
        display: inline-block;
        max-width: 3.5rem;
        margin-right: 1rem;
      }
    }
  }
`

const ReviewItem = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
    margin: 4.5rem auto;
    padding: 0 5rem 0 0;
    ${props => (props.iseven ? `padding: 0 5rem 0 0` : "padding: 0 0 0 5rem")};
    ${props => (props.iseven ? `border-right: 0.5rem solid #3b62a0` : null)};
  }

  h3 {
    ${H2Blue};
  }

  p {
    ${B2LightBlue};
  }
`

export default CustomerReviews
