import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import CustomerReviews from "../components/templates/reviews/CustomerReviews"
import ReadyHero from "../components/templates/common/ReadyHero"

const Reviews = props => {
  const { hero, customerReviews, readyHero, seoInfo } = props.data
  const heroData = hero.template.templatePageReviews
  const customerReviewsData = customerReviews.template.templatePageReviews
  const readyHeroData = readyHero.template.templatePageReviews
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <CustomerReviews data={customerReviewsData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const reviewsTempQuery = graphql`
  query reviewsTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Reviews {
          templatePageReviews {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    customerReviews: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Reviews {
          templatePageReviews {
            reviewsDisplay
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Reviews {
          templatePageReviews {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Reviews
